import Button from "@components/Button";
import Input from "@components/Input";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import {
  getLoginEmailStatusEvent,
  getResetPasswordRequestEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import validateEmailPattern from "@helpers/validateEmailPattern";
import ArrowLeft from "@icons/arrowLeft.svg";
import {
  DEFAULT_LOG_IN_USER_EVENT_ATTRIBUTES,
  LogInStatus,
  LogInType,
  LogInUserEventAttributes,
  UserCredential,
} from "@shopcashTypes/authentication";
import { AuthenticationStep } from "@shopcashTypes/authenticationStep";
import { VariantButton } from "@shopcashTypes/variantButton";
import {
  WebEngageUserEventMode,
  WebEngageUserEventType,
} from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import { useEffect, useState } from "react";
import styles from "./LoginStep.module.scss";

interface LoginStepProps {
  email: string;
  onEmailSave: (email: string) => void;
  onBackClick: () => void;
  onNextStep: (nextStep: AuthenticationStep) => void;
  onClose: () => void;
}

const LoginStep: React.FC<LoginStepProps> = ({
  email,
  onEmailSave,
  onBackClick,
  onNextStep,
  onClose,
}) => {
  const translate = useTranslations();
  const { isRtl } = useSiteConfig();
  const { trackWebEngageUserEvent } = useWebEngageTracking();
  const [updatedEmail, setUpdatedEmail] = useState<string>(email);
  const [emailMessage, setEmailMessage] = useState<string>();
  const [hasEmailError, setHasEmailError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [password, setPassword] = useState<string>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [passwordError, setPasswordError] = useState<string>();
  const { user, loginViaEmailPassword } = useAuth();
  const { trackEvent, trackUserEvent } = useGenzoTracking();
  const [logInUserEventAttributes, setLogInUserEventAttributes] = useState<
    LogInUserEventAttributes
  >(DEFAULT_LOG_IN_USER_EVENT_ATTRIBUTES);

  useEffect(() => {
    setIsDisabled(!password || !updatedEmail);
  }, [password, updatedEmail]);

  const handleGenzoTracking = (
    logInUserEventAttributes: LogInUserEventAttributes
  ): void => {
    trackEvent(
      getLoginEmailStatusEvent(
        logInUserEventAttributes.status === LogInStatus.Success
      )
    );

    setLogInUserEventAttributes(DEFAULT_LOG_IN_USER_EVENT_ATTRIBUTES);
    trackUserEvent("USER_LOGIN");
  };

  useEffect(() => {
    if (user && logInUserEventAttributes?.type === LogInType.Email) {
      handleGenzoTracking(logInUserEventAttributes);
      onClose();
    }
  }, [user, logInUserEventAttributes?.type]);

  const handleOnLoginClick = (
    event: React.FormEvent<HTMLFormElement>
  ): void => {
    event.preventDefault();
    setIsLoading(true);
    const { errorMessageKey, hasError } = validateEmailPattern(updatedEmail);
    setEmailMessage(translate(errorMessageKey));
    setHasEmailError(hasError);
    if (hasError) return;

    loginViaEmailPassword(updatedEmail, password)
      .then((r: UserCredential) => {
        if (r?.user) {
          setLogInUserEventAttributes({
            type: LogInType.Email,
            status: LogInStatus.Success,
          });
          trackWebEngageUserEvent({
            type: WebEngageUserEventType.Login,
            mode: WebEngageUserEventMode.Email,
          });
        } else {
          setLogInUserEventAttributes({
            type: LogInType.Email,
            status: LogInStatus.Failure,
          });

          setPasswordError(translate("invalid_password_title"));
        }
      })
      .catch((error) => {
        console.error(error);

        setLogInUserEventAttributes({
          type: LogInType.Email,
          status: LogInStatus.Failure,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleResetPasswordEvent = (): void => {
    const event = getResetPasswordRequestEvent();
    trackEvent(event);
  };

  const handleOnResetPasswordClick = (): void => {
    // update email to parent for other steps to use.
    onEmailSave(updatedEmail);
    handleResetPasswordEvent();

    onNextStep(AuthenticationStep.ResetPasswordStep);
  };

  return (
    <div className={clsx(styles.container, isRtl && styles.rtl)}>
      <div className={styles.backContainer} onClick={() => onBackClick()}>
        <ArrowLeft className={styles.back} />
      </div>

      <div className={styles.title}>{translate("login")}</div>
      <div className={styles.note}>{translate("onboard_title_signin")} ✨</div>
      <form onSubmit={handleOnLoginClick} data-testid="login-form">
        <Input
          id="email"
          name="email"
          type="text"
          label={translate("email")}
          message={emailMessage}
          hasError={hasEmailError}
          defaultValue={email}
          placeholder="example@email.com"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setUpdatedEmail(e.target.value)
          }
        />

        <div className={styles.passwordContainer}>
          <Input
            id="password"
            name="password"
            type="password"
            label={translate("pwd")}
            message={passwordError}
            hasError={!!passwordError}
            disabled={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            variant={VariantButton.Primary}
            large
            fullWidth
            disabled={isDisabled}
            loading={isLoading}
          >
            {translate("login")}
          </Button>
        </div>
      </form>

      <div
        className={styles.forgotPassword}
        onClick={handleOnResetPasswordClick}
      >
        {translate("pwd_forgot")} <span>{translate("pwd_reset")}</span>
      </div>
    </div>
  );
};

export default LoginStep;
