import { useState, useEffect } from "react";

const useCountDownTimer = (
  duration: number
): [string, number, (value: number) => void] => {
  const [startTimer, setStartTimer] = useState<boolean>(true);
  const [timer, setTimer] = useState<number>(duration);
  const [displayTimer, setDisplayTimer] = useState<string>("00:45");

  useEffect(() => {
    if (!startTimer) return;
    let cloneTimer = timer;
    const interval = setInterval(() => {
      --cloneTimer;
      setTimer((prevTimer) => prevTimer - 1);

      if (cloneTimer <= 0) {
        clearInterval(interval);
        setStartTimer(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      setStartTimer(false);
    };
  }, [startTimer]);

  useEffect(() => {
    if (timer < 10) {
      setDisplayTimer(`00:0${timer}`);
    } else {
      setDisplayTimer(`00:${timer}`);
    }
  }, [timer]);

  const dispatch = (value: number) => {
    setTimer(value);
    setStartTimer(true);
  };

  return [displayTimer, timer, dispatch];
};

export default useCountDownTimer;
