import Image from "@components/_shared/NextImage/v2";
import { useTranslations } from "@context/translations";
import clsx from "clsx";
import React, { useState } from "react";
import styles from "./ShopcashIntro.module.scss";

const intros = [
  {
    title: "onboard_title_a",
    text: "onboard_body_a",
    imageUrl:
      "https://zen.shopcash.com/assets/static/illustrations/phone-dark.png",
  },
  {
    title: "onboard_title_b",
    text: "onboard_body_b",
    imageUrl:
      "https://zen.shopcash.com/assets/static/illustrations/coin-box-dark.png",
  },
  {
    title: "onboard_title_c",
    text: "onboard_body_c",
    imageUrl:
      "https://zen.shopcash.com/assets/static/illustrations/shopping-bag-dark.png",
  },
];

interface ShopcashIntroProps {
  isBackgroundTransparent?: boolean;
}

const ShopcashIntro: React.FC<ShopcashIntroProps> = ({
  isBackgroundTransparent,
}) => {
  const translate = useTranslations();

  const [activeIntroIndex, setActiveIntroIndex] = useState(0);
  return (
    <div
      className={clsx(
        styles.container,
        isBackgroundTransparent && styles.transparent
      )}
    >
      <div className={styles.introList}>
        {intros.map((intro, i) => (
          <div
            key={i}
            className={clsx(
              styles.intro,
              i === activeIntroIndex && styles.active
            )}
          >
            <div className={styles.introIcon}>
              <Image
                src={intro.imageUrl}
                alt={intro.title}
                height={200}
                width={200}
              />
            </div>
            <div className={styles.title}>{translate(intro.title)}</div>
            <div className={styles.introText}>{translate(intro.text)}</div>
          </div>
        ))}
      </div>
      <div className={styles.circles}>
        <span
          onClick={() => setActiveIntroIndex(0)}
          className={clsx(activeIntroIndex === 0 && styles.active)}
        ></span>
        <span
          onClick={() => setActiveIntroIndex(1)}
          className={clsx(activeIntroIndex === 1 && styles.active)}
        ></span>
        <span
          onClick={() => setActiveIntroIndex(2)}
          className={clsx(activeIntroIndex === 2 && styles.active)}
        ></span>
      </div>
    </div>
  );
};

export default ShopcashIntro;
