import Image from "@components/_shared/NextImage/v2";
import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import ArrowIcon from "@icons/longArrowDown.svg";
import clsx from "clsx";
import styles from "./HowToEarnSummary.module.scss";

const stepOneIllustration =
  "https://zen.shopcash.com/assets/static/illustrations/signup.png";

const stepTwoIllustration =
  "https://zen.shopcash.com/assets/static/illustrations/favourite-shops.png";

const stepThreeIllustration =
  "https://zen.shopcash.com/assets/static/illustrations/earn.png";

const HowToEarnSummary = () => {
  const { isRtl } = useSiteConfig();
  const translate = useTranslations();
  return (
    <div
      data-testid="how-to-earn-summary"
      className={clsx(styles.container, isRtl && styles.rtl)}
    >
      <div className={styles.heading}>{translate("earn_title")}</div>
      <div className={styles.step}>
        <div>
          <Image src={stepOneIllustration} width="72" height="72" />
        </div>
        <div>{translate("how_to_earn_summary_step_1")}</div>
      </div>
      <div className={styles.arrowContainer}>
        <div className={styles.arrowWrap}>
          <ArrowIcon />
        </div>
      </div>
      <div className={styles.step}>
        <div>
          <Image src={stepTwoIllustration} width="72" height="72" />
        </div>
        <div>{translate("how_to_earn_summary_step_2")}</div>
      </div>
      <div className={styles.arrowContainer}>
        <div className={styles.arrowWrap}>
          <ArrowIcon />
        </div>
      </div>
      <div className={styles.step}>
        <div>
          <Image src={stepThreeIllustration} width="72" height="72" />
        </div>
        <div>{translate("how_to_earn_summary_step_3")}</div>
      </div>
    </div>
  );
};

export default HowToEarnSummary;
