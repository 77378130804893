import clsx from "clsx";
import { forwardRef, useState } from "react";
import styles from "./OtpInput.module.scss";

interface OtpInputProps {
  message?: string;
  hasError: boolean;
  onChange: (value: string) => void;
  // native input props >>>>>
  id: string;
  name: string;
  // <<<<< native input props
}

const OtpInput = forwardRef<HTMLInputElement, OtpInputProps>(
  ({ message, hasError, onChange, ...props }, ref) => {
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [value, setValue] = useState<string>();

    const handleOnValueChanged = (
      e: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const newValue = e.target.value;
      setValue(newValue);
      onChange(newValue);
    };

    return (
      <div
        className={clsx(
          styles.container,
          isFocus && styles.focused,
          !!value && styles.filled
        )}
      >
        <div className={styles.field}>
          <input
            ref={ref}
            type="tel"
            autoComplete="off"
            maxLength={6}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            onChange={handleOnValueChanged}
            {...props}
          />
        </div>

        {!!message && (
          <div className={clsx(styles.message, hasError && styles.error)}>
            {message}
          </div>
        )}
      </div>
    );
  }
);

OtpInput.displayName = "OtpInput";

export default OtpInput;
