import AuthenticationForm from "@components/AuthenticationForm";
import Modal from "@components/_shared/Modal";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { getAuthenticationFormClickEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import CrossIcon from "@icons/cross.svg";
import { AuthenticationStep } from "@shopcashTypes/authenticationStep";
import clsx from "clsx";
import React, { useEffect } from "react";
import styles from "./AuthenticationModal.module.scss";
interface AuthenticationModalProps {
  show: boolean;
  onCloseClick: () => void;
  initialStep?: AuthenticationStep;
}

const AuthenticationModal: React.FC<AuthenticationModalProps> = ({
  show,
  onCloseClick,
  initialStep,
}) => {
  const { isMobile } = useSiteConfig();
  const { trackEvent } = useGenzoTracking();

  useEffect(() => {
    if (show) {
      trackEvent(getAuthenticationFormClickEvent());
    }
  }, [show]);

  return (
    <Modal
      className={clsx(styles.modalOverride, isMobile && styles.fullScreen)}
      show={show}
      style={{ overlay: { overflowY: "auto" } }}
    >
      {!isMobile && (
        <CrossIcon
          onClick={onCloseClick}
          className={styles.closeIcon}
          data-testid="modal-close"
        ></CrossIcon>
      )}
      <AuthenticationForm initialStep={initialStep} onClose={onCloseClick} />
    </Modal>
  );
};

export default AuthenticationModal;
