import Button from "@components/Button";
import Input from "@components/Input";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import {
  getPrivacyPolicyEvent,
  getSignUpFailedEvent,
  getSignUpSuccessEvent,
  getSubmitSignUpEvent,
  getTermsAndConditionsEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import ArrowLeft from "@icons/arrowLeft.svg";
import {
  DEFAULT_SIGN_UP_USER_EVENT_ATTRIBUTES,
  SignUpStatus,
  SignUpType,
  SignUpUserEventAttributes,
  UserCredential,
} from "@shopcashTypes/authentication";
import { VariantButton } from "@shopcashTypes/variantButton";
import {
  WebEngageUserEventMode,
  WebEngageUserEventType,
} from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import styles from "./SignUpStep.module.scss";
import { useSignupForm } from "./useSignupForm";

interface SignupStepProps {
  email: string;
  onBackClick: () => void;
  onClose: () => void;
  otpRequestId: string;
}

const SignupStep: React.FC<SignupStepProps> = ({
  email,
  onBackClick,
  onClose,
  otpRequestId,
}) => {
  const translate = useTranslations();

  const { siteCode, isRtl, locale } = useSiteConfig();
  const { trackEvent } = useGenzoTracking();
  const { user } = useAuth();
  const [fields, submitForm] = useSignupForm(email, siteCode, otpRequestId);
  const autoComplete = "new-password";
  const { nameField, passwordField, repeatPasswordField } = fields;
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [signUpUserEventAttributes, setSignUpUserEventAttributes] = useState<
    SignUpUserEventAttributes
  >(DEFAULT_SIGN_UP_USER_EVENT_ATTRIBUTES);
  const { trackWebEngageUserEvent } = useWebEngageTracking();

  const handleGenzoTracking = (
    signUpUserEventAttributes: SignUpUserEventAttributes
  ): void => {
    const event =
      signUpUserEventAttributes.status === SignUpStatus.Success
        ? getSignUpSuccessEvent()
        : getSignUpFailedEvent();
    trackEvent(event);

    setSignUpUserEventAttributes(DEFAULT_SIGN_UP_USER_EVENT_ATTRIBUTES);
  };

  useEffect(() => {
    if (user && signUpUserEventAttributes?.type === SignUpType.Email) {
      handleGenzoTracking(signUpUserEventAttributes);
      onClose();
    }
  }, [user, signUpUserEventAttributes?.type]);

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    trackEvent(getSubmitSignUpEvent());
    setSubmitting(true);

    submitForm()
      .then((r: UserCredential) => {
        setSubmitting(false);

        if (r.user) {
          setSignUpUserEventAttributes({
            type: SignUpType.Email,
            status: SignUpStatus.Success,
          });

          trackWebEngageUserEvent({
            type: WebEngageUserEventType.Signup,
            mode: WebEngageUserEventMode.Email,
          });
        } else {
          setSignUpUserEventAttributes({
            type: SignUpType.Email,
            status: SignUpStatus.Failure,
          });
        }
      })
      .catch((error) => {
        // display error
        setSignUpUserEventAttributes({
          type: SignUpType.Email,
          status: SignUpStatus.Failure,
        });
        setSubmitting(false);
      });
  };

  const terms = `<a id="login_tnc" href="/${locale}/terms" target="_blank" rel="noopener noreferrer">${translate(
    "terms_conditions"
  )}</a>`;

  const privacy = `<a id="login_privacy" href="/${locale}/privacy-policy" target="_blank" rel="noopener noreferrer">${translate(
    "privacy_policy"
  )}</a>`;

  const disclaimer = translate("signup_disclaimer", [terms, privacy]);

  const handleTermsAndConditionsClick = () => {
    const event = getTermsAndConditionsEvent();
    trackEvent(event);
  };

  const handlePrivacyClick = () => {
    const event = getPrivacyPolicyEvent();
    trackEvent(event);
  };

  useEffect(() => {
    document
      .getElementById("login_tnc")
      .addEventListener("click", handleTermsAndConditionsClick);

    document
      .getElementById("login_privacy")
      .addEventListener("click", handlePrivacyClick);
  }, []);

  return (
    <div className={clsx(styles.container, isRtl && styles.rtl)}>
      <div className={styles.backContainer} onClick={() => onBackClick()}>
        <ArrowLeft className={styles.back} />
      </div>

      <div className={styles.title}>{translate("create_acc_title")}</div>
      <form onSubmit={handleOnSubmit}>
        <div>
          <Input {...nameField} autoComplete={autoComplete} />
        </div>

        <div>
          <Input {...passwordField} autoComplete={autoComplete} />
        </div>
        <div>
          <Input {...repeatPasswordField} autoComplete={autoComplete} />
        </div>
        <Button
          variant={VariantButton.Primary}
          large
          fullWidth
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {translate("create_acc_title")}
        </Button>
        <div
          className={styles.tnc}
          dangerouslySetInnerHTML={{ __html: disclaimer }}
        ></div>
      </form>
    </div>
  );
};

export default SignupStep;
