import type { UserCredential } from "firebase/auth";

export type { UserCredential };

export enum LogInType {
  Facebook = "facebook",
  Google = "google",
  Email = "email",
  Default = "",
}

export enum LogInStatus {
  Success = "success",
  Failure = "failure",
}

export interface LogInUserEventAttributes {
  type: LogInType;
  status?: LogInStatus;
}

export const DEFAULT_LOG_IN_USER_EVENT_ATTRIBUTES = {
  type: LogInType.Default,
};

export enum SignUpType {
  Email = "email",
  Default = "",
}

export enum SignUpStatus {
  Success = "success",
  Failure = "failure",
}

export interface SignUpUserEventAttributes {
  type: SignUpType;
  status?: SignUpStatus;
}

export const DEFAULT_SIGN_UP_USER_EVENT_ATTRIBUTES = {
  type: SignUpType.Default,
};
