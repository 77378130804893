import clsx from "clsx";
import { useEffect, useRef } from "react";
import styles from "./Modal.module.scss";

interface ModalProps {
  children: React.ReactNode;
  show: boolean;
  className?: string;
  style?: {
    content?: React.CSSProperties;
    overlay?: React.CSSProperties;
  };
  onOverlayClick?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  children,
  show,
  className,
  style,
  onOverlayClick,
}) => {
  useEffect(() => {
    const bodyClassList = document.body.classList;

    if (show) {
      bodyClassList.add("bodyScrollDisabled");
    }

    return () => {
      bodyClassList.remove("bodyScrollDisabled");
    };
  }, [show]);

  const modalRef = useRef<HTMLDivElement>(null);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (onOverlayClick && e.target === modalRef.current) {
      onOverlayClick();
    }
  };

  return (
    <>
      {show && (
        <div
          ref={modalRef}
          className={styles.modal}
          style={style?.overlay}
          onClick={(e) => handleOverlayClick(e)}
        >
          <div style={style?.content} className={clsx(styles.main, className)}>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
