const validateEmailPattern = (
  email: string
): { errorMessageKey: string; hasError: boolean } => {
  let errorMessageKey = "";
  let hasError = false;

  if (hasIncorrectEmailPattern(email)) {
    errorMessageKey = "email_error_message_title";
    hasError = true;
  }

  return { errorMessageKey, hasError };
};

const hasIncorrectEmailPattern = (email: string): boolean => {
  const pattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  // Trim email before checking the pattern.
  // Returns true when:
  //  - email is undefined, null or empty.
  //  - email pattern is wrong.
  return !email || (email && !pattern.test(email.trim()));
};

export default validateEmailPattern;
