import LoginStep from "@components/AuthenticationForm/LoginStep";
import OtpSignupStep from "@components/AuthenticationForm/OtpSignupStep";
import ResetPasswordStep from "@components/AuthenticationForm/ResetPasswordStep";
import SignInOptionsStep from "@components/AuthenticationForm/SignInOptionsStep";
import SignUpOptionsStep from "@components/AuthenticationForm/SignUpOptionsStep";
import SignUpStep from "@components/AuthenticationForm/SignUpStep/SignUpStep";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { AuthenticationStep } from "@shopcashTypes/authenticationStep";
import clsx from "clsx";
import { useEffect, useState } from "react";
import styles from "./AuthenticationForm.module.scss";
import ShopcashIntro from "./ShopcashIntro";

interface AuthenticationFormProps {
  initialStep?: AuthenticationStep;
  onClose: () => void;
  isOnExtensionPage?: boolean;
}

const AuthenticationForm: React.FC<AuthenticationFormProps> = ({
  initialStep,
  onClose,
  isOnExtensionPage = false,
}) => {
  const INITIAL_STEP = initialStep || AuthenticationStep.SignUpOptionsStep;
  const { isMobile, isRtl } = useSiteConfig();
  const { setReferralCode } = useAuth();
  const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
  const [stepsHistory, setStepsHistory] = useState([INITIAL_STEP]);
  const [otpRequestId, setOtpRequestId] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [hasReferralCodeApplied, setHasReferralCodeApplied] = useState<boolean>(
    false
  );

  const handleOnEmailSave = (email: string): void => {
    setEmail(email);
  };

  const handleOnBackClick = (): void => {
    if (stepsHistory.length === 1) return;

    const cloneStepsHistory = [...stepsHistory];

    // Remove current step from `stepsHistory`
    cloneStepsHistory.splice(cloneStepsHistory.length - 1, 1);
    setStepsHistory(cloneStepsHistory);
    // Get latest step from `stepsHistory` and set as the current step
    setCurrentStep(cloneStepsHistory[cloneStepsHistory.length - 1]);
  };

  const handleOnNextStep = (nextStep: AuthenticationStep): void => {
    setCurrentStep(nextStep);
    // For every change of step, we record it for back button.
    setStepsHistory((prev) => [...prev, nextStep]);
  };

  const handleOnClose = (): void => {
    onClose();
  };

  const handleReferralCodeSet = (referralCode: string): void => {
    setReferralCode(referralCode);
    setHasReferralCodeApplied(!!referralCode);
  };

  useEffect(() => {
    // Reset referral code when user opens the authentication form.
    setReferralCode("");
  }, [setReferralCode]);

  return (
    <div
      className={clsx(
        styles.container,
        isRtl && styles.rtl,
        styles[`step${currentStep}`],
        isOnExtensionPage && styles.transparent
      )}
      data-testid="authentication-form"
    >
      <div className={styles.intro}>
        <ShopcashIntro isBackgroundTransparent={isOnExtensionPage} />
      </div>

      {currentStep === AuthenticationStep.SignUpOptionsStep && (
        <SignUpOptionsStep
          referralApplied={hasReferralCodeApplied}
          onNextStep={handleOnNextStep}
          onReferralCodeSet={handleReferralCodeSet}
          onClose={handleOnClose}
        />
      )}

      {currentStep === AuthenticationStep.SignInOptionsStep && (
        <SignInOptionsStep
          onNextStep={handleOnNextStep}
          onClose={handleOnClose}
          isOnExtensionPage={isOnExtensionPage}
        />
      )}

      {currentStep === AuthenticationStep.LoginStep && (
        <LoginStep
          email={email}
          onEmailSave={handleOnEmailSave}
          onBackClick={handleOnBackClick}
          onNextStep={handleOnNextStep}
          onClose={handleOnClose}
        />
      )}

      {currentStep === AuthenticationStep.ResetPasswordStep && (
        <ResetPasswordStep
          email={email}
          onBackClick={handleOnBackClick}
          onClose={handleOnClose}
        />
      )}

      {currentStep === AuthenticationStep.OtpSignupStep && (
        <OtpSignupStep
          email={email}
          onEmailSave={handleOnEmailSave}
          onBackClick={handleOnBackClick}
          onNextStep={handleOnNextStep}
          onOtpRequestIdSet={setOtpRequestId}
          otpRequestId={otpRequestId}
        />
      )}

      {currentStep === AuthenticationStep.SignupStep && (
        <SignUpStep
          email={email}
          onBackClick={handleOnBackClick}
          onClose={handleOnClose}
          otpRequestId={otpRequestId}
        />
      )}
    </div>
  );
};

export default AuthenticationForm;
