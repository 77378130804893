import Button from "@components/Button";
import Image from "@components/_shared/NextImage/v2";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import {
  getLoginFacebookStatusEvent,
  getLoginGoogleStatusEvent,
  getLoginSignInEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import {
  DEFAULT_LOG_IN_USER_EVENT_ATTRIBUTES,
  LogInStatus,
  LogInType,
  LogInUserEventAttributes,
  UserCredential,
} from "@shopcashTypes/authentication";
import { AuthenticationStep } from "@shopcashTypes/authenticationStep";
import { GenzoAuthMethod } from "@shopcashTypes/genzo/authMethod";
import { VariantButton } from "@shopcashTypes/variantButton";
import {
  WebEngageUserEventMode,
  WebEngageUserEventType,
} from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import { useEffect, useState } from "react";
import styles from "./SignInOptionsStep.module.scss";

interface SignInOptionsStepProps {
  onNextStep: (nextStep: AuthenticationStep) => void;
  onClose: () => void;
  isOnExtensionPage?: boolean;
}

const SignInOptionsStep: React.FC<SignInOptionsStepProps> = ({
  onNextStep,
  onClose,
  isOnExtensionPage = false,
}) => {
  const translate = useTranslations();
  const { isRtl, isMobile } = useSiteConfig();
  const { trackEvent, trackUserEvent } = useGenzoTracking();
  const { user, loginViaFacebook, loginViaGoogle } = useAuth();
  const { trackWebEngageUserEvent } = useWebEngageTracking();
  const [logInUserEventAttributes, setLogInUserEventAttributes] = useState<
    LogInUserEventAttributes
  >(DEFAULT_LOG_IN_USER_EVENT_ATTRIBUTES);

  const handleSignInProviderEvent = (type: string) => {
    let event: GenzoEvent;
    switch (type) {
      case "google":
        event = getLoginSignInEvent(GenzoAuthMethod.Google, isOnExtensionPage);
        break;
      case "facebook":
        event = getLoginSignInEvent(
          GenzoAuthMethod.Facebook,
          isOnExtensionPage
        );
        break;
      case "email":
        event = getLoginSignInEvent(GenzoAuthMethod.Email, isOnExtensionPage);
        break;
      default:
        break;
    }
    trackEvent(event);
  };

  const handleGenzoTracking = (
    logInUserEventAttributes: LogInUserEventAttributes
  ): void => {
    switch (logInUserEventAttributes.type) {
      case LogInType.Google:
        trackEvent(
          getLoginGoogleStatusEvent(
            logInUserEventAttributes.status === LogInStatus.Success
          )
        );
        break;
      case LogInType.Facebook:
        trackEvent(
          getLoginFacebookStatusEvent(
            logInUserEventAttributes.status === LogInStatus.Success
          )
        );
        break;
    }
    setLogInUserEventAttributes(DEFAULT_LOG_IN_USER_EVENT_ATTRIBUTES);
    trackUserEvent("USER_LOGIN");
  };

  useEffect(() => {
    if (user && logInUserEventAttributes?.type) {
      handleGenzoTracking(logInUserEventAttributes);
      onClose();
    }
  }, [user, logInUserEventAttributes?.type]);

  const handleFacebookSignInClick = () => {
    handleSignInProviderEvent("facebook");
    loginViaFacebook().then(
      (result: UserCredential) => {
        if (result?.user) {
          setLogInUserEventAttributes({
            type: LogInType.Facebook,
            status: LogInStatus.Success,
          });
          trackWebEngageUserEvent({
            type: WebEngageUserEventType.Login,
            mode: WebEngageUserEventMode.Facebook,
          });
        } else {
          setLogInUserEventAttributes({
            type: LogInType.Facebook,
            status: LogInStatus.Failure,
          });
        }
      },
      (error: Error) => {
        console.error(error);
        setLogInUserEventAttributes({
          type: LogInType.Facebook,
          status: LogInStatus.Failure,
        });
      }
    );
  };

  const handleGoogleSignInClick = () => {
    handleSignInProviderEvent("google");

    loginViaGoogle().then(
      (result: UserCredential) => {
        if (result?.user) {
          setLogInUserEventAttributes({
            type: LogInType.Google,
            status: LogInStatus.Success,
          });
          trackWebEngageUserEvent({
            type: WebEngageUserEventType.Login,
            mode: WebEngageUserEventMode.Google,
          });
        } else {
          setLogInUserEventAttributes({
            type: LogInType.Google,
            status: LogInStatus.Failure,
          });
        }
      },
      (error) => {
        console.error(error);
        setLogInUserEventAttributes({
          type: LogInType.Google,
          status: LogInStatus.Failure,
        });
      }
    );
  };

  const handleEmailSignInClick = () => {
    handleSignInProviderEvent("email");
    onNextStep(AuthenticationStep.LoginStep);
  };

  return (
    <div className={clsx(styles.container, isRtl && styles.rtl)}>
      <div
        className={styles.signInOptionsContainer}
        data-testid="signup-options-container"
      >
        <div className={styles.titleContainer}>
          {isMobile && (
            <div className={styles.navigation}>
              <div className={styles.header}>
                {translate("onboarding_signin")}
              </div>
              <div
                className={clsx(styles.closeText)}
                onClick={onClose}
                data-testid="modal-close"
              >
                {translate("close_title")}
              </div>
            </div>
          )}
          <h1 className={styles.title}>{translate("login_title")}</h1>
          <div className={styles.subtitle}>
            <div className={styles.subtitlePrompt}>
              {translate("joinnow_account")}
            </div>
            <div
              className={styles.subtitleLink}
              onClick={() => onNextStep(AuthenticationStep.SignUpOptionsStep)}
            >
              {translate("join_now_signin")}
            </div>
          </div>
        </div>

        <div className={styles.thirdPartyButton}>
          <Button
            variant={VariantButton.Google}
            onClick={handleGoogleSignInClick}
          >
            <div className={styles.logo}>
              <Image
                src="https://zen.shopcash.com/assets/static/icons/google-icon.png"
                alt="google icon"
                width={24}
                height={24}
              />
            </div>
            {translate("login_google")}
          </Button>

          <Button
            variant={VariantButton.Facebook}
            onClick={handleFacebookSignInClick}
          >
            <div className={styles.logo}>
              <Image
                src="https://zen.shopcash.com/assets/static/icons/facebook-icon.png"
                alt="facebook icon"
                width={24}
                height={24}
              />
            </div>
            {translate("login_facebook")}
          </Button>
        </div>

        <Button
          variant={VariantButton.EmailAuth}
          large
          fullWidth
          onClick={handleEmailSignInClick}
        >
          <div className={styles.logo}>
            <Image
              src="https://zen.shopcash.com/assets/static/icons/email-icon.png"
              alt="email icon"
              width={24}
              height={24}
            />
          </div>
          {translate("login_email")}
        </Button>
      </div>
    </div>
  );
};

export default SignInOptionsStep;
