import { useSiteConfig } from "@context/siteConfig";
import EyeClose from "@icons/eyeClose.svg";
import EyeOpen from "@icons/eyeOpen.svg";
import clsx from "clsx";
import { ChangeEvent, FC, FocusEvent, useEffect, useState } from "react";
import styles from "./Input.module.scss";

const Input: FC<InputProps> = ({
  type,
  label,
  message,
  hasError,
  autoComplete = "no",
  onChange,
  onBlur,
  ...props
}) => {
  const { isRtl } = useSiteConfig();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [value, setValue] = useState<string>();
  const [dynamicType, setDynamicType] = useState<string>();
  useEffect(() => {
    setValue(props.defaultValue);
  }, []);

  const handleOnValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (onChange) onChange(e);
  };

  const handleOnTypeChanged = (type: string): void => {
    setDynamicType(type);
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocus(false);
    const newValue = e.target.value;
    setValue(newValue);
    if (onBlur) onBlur(e);
  };

  return (
    <div
      className={clsx(
        styles.container,
        props.disabled && styles.disabled,
        isFocus && styles.focused,
        !!value && styles.filled,
        isRtl && styles.rtl
      )}
    >
      <div className={styles.field}>
        <label htmlFor={props.id}>{label}</label>

        <input
          type={dynamicType || type}
          autoComplete={autoComplete}
          onFocus={() => setIsFocus(true)}
          onBlur={handleOnBlur}
          onChange={handleOnValueChanged}
          {...props}
        />
        {type === "password" && (
          <EyeIcon
            isDisabled={props.disabled}
            onTypeChanged={handleOnTypeChanged}
          />
        )}
      </div>

      {!!message && (
        <div className={clsx(styles.message, hasError && styles.error)}>
          {message}
        </div>
      )}
    </div>
  );
};

interface EyeIconProps {
  isDisabled: boolean;
  onTypeChanged: (type: string) => void;
}

const EyeIcon: FC<EyeIconProps> = ({ isDisabled, onTypeChanged }) => {
  const [isEyeOpen, setIsEyeOpen] = useState<boolean>(false);
  const handleOnEyeClick = () => {
    if (isDisabled) return;

    setIsEyeOpen((prevIsEyeOpen) => !prevIsEyeOpen);
    onTypeChanged(!isEyeOpen ? "text" : "password");
  };

  return (
    <div
      className={clsx(styles.iconContainer, isDisabled && styles.disabled)}
      onClick={handleOnEyeClick}
    >
      {isEyeOpen ? (
        <EyeOpen className={styles.icon} />
      ) : (
        <EyeClose className={styles.icon} />
      )}
    </div>
  );
};

export default Input;
