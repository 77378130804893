import { validateReferralCode } from "@api/referrals";
import ReferralForm from "@components/AuthenticationForm/ReferralForm";
import Button from "@components/Button";
import Image from "@components/_shared/NextImage/v2";
import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useWebEngageTracking } from "@context/tracking/webEngage";
import { useTranslations } from "@context/translations";
import {
  getLoginFacebookStatusEvent,
  getLoginGoogleStatusEvent,
  getLoginSignUpEvent,
  getPrivacyPolicyEvent,
  getReferralCodeStatusEvent,
  getTermsAndConditionsEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import {
  DEFAULT_LOG_IN_USER_EVENT_ATTRIBUTES,
  LogInStatus,
  LogInType,
  LogInUserEventAttributes,
  UserCredential,
} from "@shopcashTypes/authentication";
import { AuthenticationStep } from "@shopcashTypes/authenticationStep";
import { GenzoAuthMethod } from "@shopcashTypes/genzo/authMethod";
import { VariantButton } from "@shopcashTypes/variantButton";
import {
  WebEngageUserEventMode,
  WebEngageUserEventType,
} from "@shopcashTypes/webEngageTracking";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import HowToEarnSummary from "./HowToEarnSummary";
import styles from "./SignUpOptionsStep.module.scss";

interface SignUpOptionsStepProps {
  referralApplied: boolean;
  onNextStep: (nextStep: AuthenticationStep) => void;
  onReferralCodeSet: (referralCode: string) => void;
  onClose: () => void;
}

const SignUpOptionsStep: React.FC<SignUpOptionsStepProps> = ({
  referralApplied,
  onNextStep,
  onReferralCodeSet,
  onClose,
}) => {
  const translate = useTranslations();
  const { siteCode, isRtl, locale, isMobile } = useSiteConfig();
  const { trackEvent, trackUserEvent } = useGenzoTracking();
  const { user, loginViaFacebook, loginViaGoogle } = useAuth();
  const { trackWebEngageUserEvent } = useWebEngageTracking();
  const [validatingReferralCode, setValidatingReferralCode] = useState(false);
  const router = useRouter();
  const [logInUserEventAttributes, setLogInUserEventAttributes] = useState<
    LogInUserEventAttributes
  >(DEFAULT_LOG_IN_USER_EVENT_ATTRIBUTES);
  const referralFromLink = (router.query.referralCode as string) || null;

  const terms = `<a id="login_tnc" href="/${locale}/terms" target="_blank" rel="noopener noreferrer">${translate(
    "terms_conditions"
  )}</a>`;

  const privacy = `<a id="login_privacy" href="/${locale}/privacy-policy" target="_blank" rel="noopener noreferrer">${translate(
    "privacy_policy"
  )}</a>`;
  const disclaimer = translate("signup_disclaimer", [terms, privacy]);
  const [referralCodeErrorMessage, setReferralCodeErrorMessage] = useState<
    string
  >();

  const handleTermsAndConditionsClick = () => {
    const event = getTermsAndConditionsEvent();
    trackEvent(event);
  };

  const handlePrivacyClick = () => {
    const event = getPrivacyPolicyEvent();
    trackEvent(event);
  };

  useEffect(() => {
    document
      .getElementById("login_tnc")
      .addEventListener("click", handleTermsAndConditionsClick);

    document
      .getElementById("login_privacy")
      .addEventListener("click", handlePrivacyClick);
  }, []);

  const handleReferralCodeStatusEvent = (success: boolean) => {
    const event = getReferralCodeStatusEvent(success);
    trackEvent(event);
  };

  const handleReferralCodeSubmit = async (
    referralCode: string
  ): Promise<void> => {
    setValidatingReferralCode(true);
    const isReferralCodeValid: boolean = await validateReferralCode(
      referralCode,
      siteCode
    );

    setTimeout(() => setValidatingReferralCode(false), 200);

    if (isReferralCodeValid) {
      setReferralCodeErrorMessage("");
      onReferralCodeSet(referralCode);
      handleReferralCodeStatusEvent(true);
    } else {
      setReferralCodeErrorMessage(translate("sign_up_referral_invalid"));
      onReferralCodeSet("");
      handleReferralCodeStatusEvent(false);
    }
  };

  const handleSignUpProviderEvent = (type: string) => {
    let event: GenzoEvent;
    switch (type) {
      case "google":
        event = getLoginSignUpEvent(GenzoAuthMethod.Google);
        break;
      case "facebook":
        event = getLoginSignUpEvent(GenzoAuthMethod.Facebook);
        break;
      case "email":
        event = getLoginSignUpEvent(GenzoAuthMethod.Email);
        break;
      default:
        break;
    }
    trackEvent(event);
  };

  const handleGenzoTracking = (
    logInUserEventAttributes: LogInUserEventAttributes
  ): void => {
    switch (logInUserEventAttributes.type) {
      case LogInType.Google:
        trackEvent(
          getLoginGoogleStatusEvent(
            logInUserEventAttributes.status === LogInStatus.Success
          )
        );
        break;
      case LogInType.Facebook:
        trackEvent(
          getLoginFacebookStatusEvent(
            logInUserEventAttributes.status === LogInStatus.Success
          )
        );
        break;
    }
    setLogInUserEventAttributes(DEFAULT_LOG_IN_USER_EVENT_ATTRIBUTES);
    trackUserEvent("USER_LOGIN");
  };

  useEffect(() => {
    if (user && logInUserEventAttributes?.type) {
      handleGenzoTracking(logInUserEventAttributes);
      onClose();
    }
  }, [user, logInUserEventAttributes?.type]);

  const handleFacebookSignInClick = () => {
    const eventKey = "facebook";
    handleSignUpProviderEvent(eventKey);
    loginViaFacebook().then(
      (result: UserCredential) => {
        if (result?.user) {
          setLogInUserEventAttributes({
            type: LogInType.Facebook,
            status: LogInStatus.Success,
          });
          trackWebEngageUserEvent({
            type: WebEngageUserEventType.Login,
            mode: WebEngageUserEventMode.Facebook,
          });
        } else {
          setLogInUserEventAttributes({
            type: LogInType.Facebook,
            status: LogInStatus.Failure,
          });
        }
      },
      (error: Error) => {
        console.error(error);
        setLogInUserEventAttributes({
          type: LogInType.Facebook,
          status: LogInStatus.Failure,
        });
      }
    );
  };

  const handleGoogleSignInClick = () => {
    const eventKey = "google";
    handleSignUpProviderEvent(eventKey);

    loginViaGoogle().then(
      (result: UserCredential) => {
        if (result?.user) {
          setLogInUserEventAttributes({
            type: LogInType.Google,
            status: LogInStatus.Success,
          });
          trackWebEngageUserEvent({
            type: WebEngageUserEventType.Login,
            mode: WebEngageUserEventMode.Google,
          });
        } else {
          setLogInUserEventAttributes({
            type: LogInType.Google,
            status: LogInStatus.Failure,
          });
        }
      },
      (error) => {
        console.error(error);
        setLogInUserEventAttributes({
          type: LogInType.Google,
          status: LogInStatus.Failure,
        });
      }
    );
  };

  useEffect(() => {
    if (referralFromLink?.length > 0)
      void handleReferralCodeSubmit(referralFromLink);
  }, [referralFromLink]);

  return (
    <div className={clsx(styles.container, isRtl && styles.rtl)}>
      <div
        className={clsx(
          styles.referralContainer,
          referralApplied && styles.referralApplied
        )}
      >
        <ReferralForm
          applied={referralApplied}
          errorMessage={referralCodeErrorMessage}
          referralFromLink={referralFromLink}
          validating={validatingReferralCode}
          onSubmit={handleReferralCodeSubmit}
          onReferralCodeSet={onReferralCodeSet}
        />
      </div>
      <div
        className={styles.signUpOptionsContainer}
        data-testid="signup-options-container"
      >
        <div className={styles.titleContainer}>
          {isMobile && (
            <div className={styles.navigation}>
              <div className={styles.header}>{translate("join_now")}</div>
              <div
                className={clsx(styles.closeText)}
                onClick={onClose}
                data-testid="modal-close"
              >
                {translate("close_title")}
              </div>
            </div>
          )}
          <h1 className={styles.title}>{translate("login_title")}</h1>
          <div className={styles.subtitle}>
            <div className={styles.subtitlePrompt}>
              {translate("signin_account_exists")}
            </div>
            <div
              className={styles.subtitleLink}
              onClick={() => onNextStep(AuthenticationStep.SignInOptionsStep)}
            >
              {translate("signin_title")}
            </div>
          </div>
        </div>

        <div className={styles.thirdPartyButton}>
          <Button
            variant={VariantButton.Google}
            onClick={handleGoogleSignInClick}
          >
            <div className={styles.logo}>
              <Image
                src="https://zen.shopcash.com/assets/static/icons/google-icon.png"
                alt="google icon"
                width={24}
                height={24}
              />
            </div>
            {translate("joinwith_google")}
          </Button>

          <Button
            variant={VariantButton.Facebook}
            onClick={handleFacebookSignInClick}
          >
            <div className={styles.logo}>
              <Image
                src="https://zen.shopcash.com/assets/static/icons/facebook-icon.png"
                alt="facebook icon"
                width={24}
                height={24}
              />
            </div>
            {translate("joinwith_facebook")}
          </Button>
        </div>

        <Button
          variant={VariantButton.EmailAuth}
          large
          fullWidth
          onClick={() => {
            handleSignUpProviderEvent("email");
            onNextStep(AuthenticationStep.OtpSignupStep);
          }}
        >
          <div className={styles.logo}>
            <Image
              src="https://zen.shopcash.com/assets/static/icons/email-icon.png"
              alt="email icon"
              width={24}
              height={24}
            />
          </div>
          {translate("joinwith_email")}
        </Button>

        <div
          data-testid="terms-text"
          className={styles.tnc}
          dangerouslySetInnerHTML={{ __html: disclaimer }}
        ></div>
      </div>
      {isMobile && <HowToEarnSummary />}
    </div>
  );
};

export default SignUpOptionsStep;
