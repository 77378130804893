import { useAuth } from "@context/authentication";
import { useSiteConfig } from "@context/siteConfig";
import { useTranslations } from "@context/translations";
import { UserCredential } from "@shopcashTypes/authentication";
import React, { useState } from "react";
import styles from "./SignUpStep.module.scss";

const pattern = new RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
);

const checkIconUrl =
  "https://assets.wego.com/image/upload/v1604617495/web/shopcash/icons-generic-icons-check.png";

export const useNameField = () => {
  const translate = useTranslations();

  const [nameField, setNameField] = useState<InputProps>({
    id: "name",
    name: "name",
    type: "text",
    label: translate("name"),
    hasError: false,
    message: translate("enter_name_title"),
    value: "",
  });

  nameField.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameField({
      ...nameField,
      value: e.target.value || "",
    });
  };

  const validateNameField = (): boolean => {
    if (nameField.value.length < 2 || !/^[a-z0-9- ]+$/i.test(nameField.value)) {
      nameField.hasError = true;
    } else {
      nameField.hasError = false;
    }
    setNameField(nameField);
    return !nameField.hasError;
  };

  return [nameField, validateNameField] as const;
};

export const usePasswordsField = () => {
  const translate = useTranslations();

  const [passwordField, setPasswordField] = useState<InputProps>({
    id: "password",
    name: "password",
    message: translate("pwd_description"),
    label: translate("pwd"),
    hasError: false,
    type: "password",
    value: "",
  });

  const [repeatPasswordField, setRepeatPasswordField] = useState<InputProps>({
    id: "repeatPassword",
    name: "repeatPassword",
    label: translate("pwd_repeat"),
    hasError: false,
    type: "password",
    value: "",
  });

  const validatePassword = (): InputProps => {
    const newPasswordField = { ...passwordField };
    const newValue = newPasswordField.value;
    if (!newValue || (newValue && !pattern.test(newValue.trim()))) {
      newPasswordField.hasError = true;
      newPasswordField.message = translate("pwd_description");
    } else {
      newPasswordField.hasError = false;
      newPasswordField.message = (
        <div className={styles.messageContainer}>
          <span> {translate("pwd_description")} </span>
          <span>
            <img src={checkIconUrl} />
          </span>
        </div>
      );
    }
    return newPasswordField;
  };

  const validateRepeatPassword = (passwordField: InputProps): InputProps => {
    if (
      !passwordField.hasError &&
      passwordField.value !== repeatPasswordField.value
    ) {
      repeatPasswordField.hasError = true;
      repeatPasswordField.message = translate("pwd_mismatch");
    } else if (
      !passwordField.hasError &&
      passwordField.value === repeatPasswordField.value
    ) {
      repeatPasswordField.hasError = false;
      repeatPasswordField.message = (
        <div className={styles.messageContainer}>
          <span>{translate("password_matched_title")}</span>
          <span>
            <img src={checkIconUrl} />
          </span>
        </div>
      );
    }

    return repeatPasswordField;
  };

  const validatePasswords = (): boolean => {
    const passwordField = validatePassword();
    const repeatPasswordField = validateRepeatPassword(passwordField);

    setPasswordField(passwordField);
    setRepeatPasswordField(repeatPasswordField);
    return !passwordField.hasError && !repeatPasswordField.hasError;
  };

  // i need to set this outside, to bind it to this function
  // so that passwordField is updated
  passwordField.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\s+/g, "");
    setPasswordField({
      ...passwordField,
      value: newValue || "",
    });
  };

  repeatPasswordField.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\s+/g, "");
    setRepeatPasswordField({
      ...repeatPasswordField,
      value: newValue || "",
    });
  };

  return [passwordField, repeatPasswordField, validatePasswords] as const;
};

interface SignUpFormFields {
  nameField: InputProps;
  passwordField: InputProps;
  repeatPasswordField: InputProps;
}

export const useSignupForm = (
  email: string,
  siteCode: string,
  otpRequestId: string
): [SignUpFormFields, () => Promise<UserCredential>] => {
  const { locale } = useSiteConfig();
  const { signupViaEmailPassword } = useAuth();
  const [nameField, validateNameField] = useNameField();
  const [
    passwordField,
    repeatPasswordField,
    validatePasswords,
  ] = usePasswordsField();

  const submitForm = (): Promise<UserCredential> => {
    if (validateNameField() && validatePasswords()) {
      const request: FirebaseSignUpViaPasswordRequest = {
        email: email,
        name: nameField.value,
        password: passwordField.value,
        siteCode,
        language: locale,
        otpRequestId: otpRequestId,
      };
      return signupViaEmailPassword(request);
    } else {
      return Promise.reject(false);
    }
  };

  const fields: SignUpFormFields = {
    nameField,
    passwordField,
    repeatPasswordField,
  };
  return [fields, submitForm];
};
