import { resetPassword } from "@api/authentications";
import Button from "@components/Button";
import Input from "@components/Input";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import {
  getReferralCodeSubmitEvent,
  getResetPasswordConfirmEvent,
  getResetPasswordStatusEvent,
} from "@helpers/genzo/genzoEventActionsBuilder";
import validateEmailPattern from "@helpers/validateEmailPattern";
import ArrowLeft from "@icons/arrowLeft.svg";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./ResetPasswordStep.module.scss";

interface ResetPasswordStepProps {
  email: string;
  onBackClick: () => void;
  onClose: () => void;
}

enum ResetPasswordState {
  Form = "form",
  Confirmation = "confirmation",
}

const ResetPasswordStep: React.FC<ResetPasswordStepProps> = ({
  email,
  onBackClick,
  onClose,
}) => {
  const { isRtl } = useSiteConfig();
  const [updatedEmail, setUpdatedEmail] = useState<string>(email);
  const [resetPasswordState, setResetPasswordState] = useState<
    ResetPasswordState
  >(ResetPasswordState.Form);

  return (
    <div className={clsx(styles.container, isRtl && styles.rtl)}>
      <div className={styles.backContainer} onClick={() => onBackClick()}>
        <ArrowLeft className={styles.back} />
      </div>

      {resetPasswordState === ResetPasswordState.Form && (
        <ResetPasswordForm
          email={email}
          updatedEmail={updatedEmail}
          onEmailChange={setUpdatedEmail}
          onResetPasswordClick={() =>
            setResetPasswordState(ResetPasswordState.Confirmation)
          }
        />
      )}
      {resetPasswordState === ResetPasswordState.Confirmation && (
        <ResetPasswordConfirmation
          email={updatedEmail}
          onConfirmClick={onClose}
        />
      )}
    </div>
  );
};

interface ResetPasswordFormProps {
  email: string;
  updatedEmail: string;
  onEmailChange: Dispatch<SetStateAction<string>>;
  onResetPasswordClick: () => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  email,
  updatedEmail,
  onEmailChange,
  onResetPasswordClick,
}) => {
  const router = useRouter();
  const translate = useTranslations();
  const { trackEvent } = useGenzoTracking();

  const [emailMessage, setEmailMessage] = useState<string>();
  const [hasEmailError, setHasEmailError] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    setIsDisabled(!updatedEmail);
  }, [updatedEmail]);

  const handleResetPaswwordStatusEvent = (success: boolean) => {
    const event = getResetPasswordStatusEvent(success);
    trackEvent(event);
  };

  const handleResetPaswwordSubmitEvent = (): void => {
    const event = getReferralCodeSubmitEvent();
    trackEvent(event);
  };

  const handleOnResetPasswordClick = (): Promise<void> => {
    const { errorMessageKey, hasError } = validateEmailPattern(updatedEmail);
    handleResetPaswwordSubmitEvent();
    setEmailMessage(translate(errorMessageKey));
    setHasEmailError(hasError);
    if (hasError) {
      handleResetPaswwordStatusEvent(false);
      return;
    }

    resetPassword(updatedEmail, router.locale.includes("ar") ? "ar" : "en")
      .then(() => {
        // When email is valid, proceed to reset password confirmation state.
        handleResetPaswwordStatusEvent(true);
        onResetPasswordClick();
      })
      .catch((error: Error) => {
        handleResetPaswwordStatusEvent(false);
        console.error(error);
      });
  };

  return (
    <>
      <div className={styles.title}>{translate("pwd_reset")}</div>
      <div className={styles.note}>{translate("password_reset_title")}</div>

      <Input
        id="email"
        name="email"
        type="text"
        label={translate("email")}
        message={emailMessage}
        defaultValue={email}
        hasError={hasEmailError}
        placeholder="example@email.com"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onEmailChange(e.target.value)
        }
      />

      <div className={styles.buttonContainer}>
        <Button
          variant={VariantButton.Primary}
          large
          fullWidth
          disabled={isDisabled}
          onClick={handleOnResetPasswordClick}
        >
          {translate("pwd_reset")}
        </Button>
      </div>
    </>
  );
};

interface ResetPasswordConfirmationProps {
  email: string;
  onConfirmClick: () => void;
}
const ResetPasswordConfirmation: React.FC<ResetPasswordConfirmationProps> = ({
  email,
  onConfirmClick,
}) => {
  const translate = useTranslations();
  const { trackEvent } = useGenzoTracking();
  const handleResetConfirmClickEvent = () => {
    const event = getResetPasswordConfirmEvent();
    trackEvent(event);
  };
  return (
    <>
      <div className={styles.title}>{translate("login")}</div>
      <div className={styles.note}>
        {translate("pwdreset_body", [""])}
        <div className={styles.emailText}>{email}</div>
      </div>

      <div className={styles.subNote}>{translate("pwdreset_extra")}</div>

      <div className={styles.buttonContainer}>
        <Button
          variant={VariantButton.Primary}
          large
          fullWidth
          onClick={() => {
            handleResetConfirmClickEvent();
            onConfirmClick();
          }}
        >
          {translate("got_it")}
        </Button>
      </div>
    </>
  );
};

export default ResetPasswordStep;

// -=-=-=-=-
// TO DOs
//  - Apply loading state button on reset password button to avoid multiple request.
