import Button from "@components/Button";
import Input from "@components/Input";
import { useSiteConfig } from "@context/siteConfig";
import { useGenzoTracking } from "@context/tracking/genzo";
import { useTranslations } from "@context/translations";
import { getReferralCodeSubmitEvent } from "@helpers/genzo/genzoEventActionsBuilder";
import ChevronIcon from "@icons/chevron.svg";
import { VariantButton } from "@shopcashTypes/variantButton";
import clsx from "clsx";
import React, { useState } from "react";
import styles from "./ReferralForm.module.scss";

interface ReferralFormProps {
  applied: boolean;
  errorMessage: string;
  referralFromLink?: string;
  validating: boolean;
  onSubmit: (referralCode: string) => void;
  onReferralCodeSet: (referralCode: string) => void;
}

const ReferralForm: React.FC<ReferralFormProps> = ({
  applied,
  errorMessage,
  referralFromLink = "",
  validating,
  onSubmit,
  onReferralCodeSet,
}) => {
  const translate = useTranslations();
  const { isRtl } = useSiteConfig();
  const { trackEvent } = useGenzoTracking();

  const [referralCode, setReferralCode] = useState<string>(
    referralFromLink || ""
  );
  const [opened, setOpened] = useState(referralFromLink?.length > 0);

  const handleReferralCodeSubmitEvent = () => {
    const event = getReferralCodeSubmitEvent();
    trackEvent(event);
  };

  const handleReferralCodeSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    handleReferralCodeSubmitEvent();
    onSubmit(referralCode);
  };

  const handleReferralCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setReferralCode(e.target.value.toUpperCase());
    onReferralCodeSet(""); // set to blank to reset applied status
  };

  const handleOpenClick = () => {
    setOpened(true);
  };

  return (
    <>
      <form
        className={clsx(
          styles.container,
          isRtl && styles.rtl,
          (opened || errorMessage) && styles.opened
        )}
        onSubmit={handleReferralCodeSubmit}
      >
        <div
          className={styles.referralTitleContainer}
          onClick={handleOpenClick}
        >
          <span className={styles.referralTitle}>
            {translate("referral_title")}
          </span>
          <ChevronIcon className={styles.chevron} />
        </div>
        <div className={styles.referralInput}>
          <div
            className={clsx(
              styles.referralField,
              referralCode.length > 0 && styles.filled
            )}
          >
            <Input
              id="referralCode"
              name="referralCode"
              type="text"
              label={translate("referral_code")}
              message={
                <span
                  className={clsx(styles.message, errorMessage && styles.error)}
                >
                  {errorMessage || translate("sign_up_referral_new_user")}
                </span>
              }
              hasError={!!errorMessage}
              placeholder={translate("enter_referral_code")}
              value={referralCode}
              onChange={handleReferralCodeChange}
            />
          </div>

          <div className={styles.referralButton}>
            <Button
              variant={VariantButton.OutlineSecondary}
              loading={validating}
            >
              {!applied && !validating
                ? translate("apply")
                : translate("code_applied")}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ReferralForm;
